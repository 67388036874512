// frame rates
const maxVideoBitRate = 450;
const maxVideoFrameRate = 20;
const lowVideoBitRate = 150;
const lowVideoFrameRate = 10;
const screenShareBitRate = 500;
const screenShareFrameRate = 5;
const bitRateScaleFactor = 1000;

// track names
const videoTrackName = 'camera';
const videoPreviewTrackName = 'videopreview';
const audioTrackName = 'mic';
const screenTrackName = 'screen';
const dataTrackName = 'datatrack';

// video dimensions
const lowVideoWidth = 352;
const lowVideoHeight = 288;
const avgVideoWidth = 480;
const avgVideoHeight = 360;
const maxVideoWidth = 640;
const maxVideoHeight = 360;
const maxScreenShareWidth = 1920;
const maxScreenShareHeight = 1080;

// fireFox screen constraints
const maxScreenShareWidthFirefox = 1280;
const maxScreenShareHeightFirefox = 720;
const screenShareFrameRateFirefox = 15;

export const peerToPeerGroupCallDimensions = {
  lowVideoWidth: 240,
  lowVideoHeight: 180,
  standardVideoWidth: 480,
  standardVideoHeight: 360,
  maxVideoWidth: 640,
  maxVideoHeight: 360,
  maxScreenShareWidth: 1280,
  maxScreenShareHeight: 720,
};

export const peerToPeerDirectCallDimensions = {
  lowVideoWidth: 640,
  lowVideoHeight: 360,
  standardVideoWidth: 640,
  standardVideoHeight: 360,
  maxVideoWidth: 1280,
  maxVideoHeight: 720,
  maxScreenShareWidth: 1280,
  maxScreenShareHeight: 720,
  frameRate: 30,
};

export const smallGroupVideoDimensions = {
  lowVideoWidth: 640,
  lowVideoHeight: 360,
  standardVideoWidth: 640,
  standardVideoHeight: 360,
  maxVideoWidth: 640,
  maxVideoHeight: 360,
  maxScreenShareWidth: 1280,
  maxScreenShareHeight: 720,
};

export const groupVideoDimensions = {
  lowVideoWidth: 240,
  lowVideoHeight: 180,
  standardVideoWidth: 352,
  standardVideoHeight: 288,
  maxVideoWidth: 480,
  maxVideoHeight: 360,
  maxScreenShareWidth: 1280,
  maxScreenShareHeight: 720,
};

// Browser
const CHROME = 'chrome';
const opera = 'opera';
const firefox = 'firefox';
const electron = 'electron';
const safari = 'safari';
const edge = 'edge';

// support versions
const chromeBuiltInScreenShareVersion = 72;
const electronBuildInScreenShareVersion = 2;

// Room
const maxNumberOfParticipants = 50;

export const PEER_TO_PEER = 'peerToPeer';
export const LARGE_GROUP = 'group';
export const SMALL_GROUP = 'smallRoom';
export const PEER_TO_PEER_DIRECT_CALL = 'PEER_TO_PEER_DIRECT_CALL';

// plan types
export const FREE_PLAN = 'free';
export const PRO_PLAN = 'pro';
export const BUSINESS_PLAN = 'business';

// callTypes
const peerToPeerCall = 'peerToPeer';
const groupCall = 'groupCall';

// room types
const ROOM_TYPE_TELECAST = 'ROOM_TYPE_TELECAST';

// mediaSettings
const savedMediaSettingsKey = 'MediaSettings';

const TEAM_DEFAULT_LIMIT = 10;

const endPoint = {
  postInvite: '/api/client/v1/invite/videocall',
  fetchTeamMembers: '/api/client/v1/account/users',
  fetchInvitedMembers: '/api/python/Team/invitations',
  deleteInvitation: '/Cancel/Invitation',
  fetchPlan: '/api/python/payment/v1/plan',
  newInvite: '/Invite/team/member',
  fetchBillingInfo: '/api/python/v1/billing/info',
  fetchInvoiceInfo: '/api/python/v1/payment/invoice',
  updatePlan: '/api/python/payment/v1/plan/upgrade',
  updatePlanInfo: '/api/python/payment/v2/plan/upgrade',
  newInviteV2: '/api/v2/invite/user',
  deleteUser: '/api/v2/delete/user',
};

const ENCODED_PLAN_TYPES = {
  free: 'ab6fab05',
  pro: '09e954b1',
  business: 'ebc62b4d',
};

const JBLINK_PLAN_TYPES = {
  pro: '4997',
  business: '4998',
};

// response
const SUCCESS_RESPONSE = 'success';
const FAILURE_RESPONSE = 'failed';
const ERROR_RESPONSE = 'error';

const PRO_UPGRADE_PATH = '/payment/09e954b1';
const BUSINESS_UPGRADE_PATH = '/payment/ebc62b4d';

const PRO_TAG_LINE = 'Great for small remote teams and growing businesses.';
const BUSINESS_TAG_LINE =
  'Ideal for large distributed teams and big enterprises.';
const FREE_TAG_LINE = 'Perfect for freelancers, employees or startups.';

const INVITE_TYPE = ['Team member', 'Admin'];

const USER_OWNER_ROLES = ['OWNER'];

const MASTER = 'MASTER';
const DISCOVER = 'DISCOVER';
const AMEX = 'AMEX';
const MAESTRO = 'MAESTRO';
const MASTERCARD = 'MASTERCARD';
const JCB = 'JCB';

const FIREFOX_VIDEO_PERMISION_TITLE = 'Camera and Microphone are blocked';
const FIREFOX_VIDEO_PERMISION_DESC =
  'Teleport video requires access to your camera and microphone. Click the camera blocked icon in your browsers address bar.';

const FREE_BILLING_RESPONSE = {
  data: {
    plan: { cost: 0, type: 'ab6fab05', name: 'free' },
    team: { inUse: 1, size: '1' },
  },
};

const planType = {
  pro: 'PRO',
  business: 'BUSINESS',
  free: 'FREE',
};

// video call constraints
const JOINROOM = 'JOINROOM';
const TOGGLE_SCREEN_SHARE = 'TOGGLESCREENSHARE';
const ENDCALL = 'ENDCALL';
export const END_DIRECT_CALL = 'END_DIRECT_CALL';
const TOGGLE_CAMERA = 'TOGGLECAMERA';
const TOGGLE_MIC = 'TOGGLEMIC';
const TOGGLE_FULLSCREEN = 'TOGGLEFULLSCREEN';
const DIRECT_CALL_ANSWERED = 'DIRECT_CALL_ANSWERED';
export const LARGE_GROUP_TRANSITION = 'LARGE_GROUP_TRANSITION';
export const CREATE_ROOM = 'CREATE_ROOM';

export const DIRECT_CALL_TIMEOUT = 'DIRECT_CALL_TIMEOUT';
const TIMEOUT = 'timeout';
const INTERVAL = 'interval';

const WINDOW_FOCUS_MODULE = 'WINDOW_FOCUS_MODULE';
const WINDOW_BLUR_MODULE = 'WINDOW_BLUR_MODULE';

// view perferences
const GRID_VIEW = 'GRID_VIEW';
const SPEAKER_VIEW = 'SPEAKER_VIEW';

// localStorage variables
const VIEW_PREFERENCES = 'viewPreferences';
const CLAIMED_ROOMS = 'claimedRooms';
const SUBDOMAIN = 'subdomain';

// subscription rules
const SUBSCRIPTION_RULES_INCLUDE = 'include';
const SUBSCRIPTION_RULES_EXCLUDE = 'exclude';
const NO_OF_PARTICIPANTS_TO_SUBSCRIBE = 3;
const NO_OF_USER_SELECTED_VIDEOS = 1;

// twilio error codes
const SIGNALLING_ERROR_CODES = [53001, 53000, 53002, 53003, 53004, 53204];
const DUPLICATE_PARTICIPANT_ERROR_CODE = 53205;
const TWILIO_TOKEN_EXPIRES_ERROR_CODE = 20157;
export const INVALID_TWILIO_TOKEN_CODE = 20102;

// states of call for p2p
const CALL_STATE = {
  CONNECTED: 'CONNECTED',
  RINGING: 'RINGING',
  ENDED: 'ENDED',
};

const E4X_AUDIO_STATE = {
  FIXED: 'finished fixing audio',
  NEW_DEVICE_FOUND: 'audio device(s) found/removed',
  CONFIGURING_AUDIO: 'configuring internals to fix',
  FIXING_AUDIO: 'fixing audio',
  MIC_FIXED: 'mic fixed',
  SPEAKER_FIXED: 'speaker fixed',
  NOT_FIXED: 'NOT_FIXED',
  SPEAKER_FIXING: 'speaker fixing',
  MIC_FIXING: 'mic fixing',
};

const ENV = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};
const TIMEOUT_ERROR = new Error('time Out');
TIMEOUT_ERROR.code = 408;

const STATS_FROM_DATE = '2019-05-18'; // old stats table has been deprecated and after this point we are fetching from activity table

// chat connection states
const CHAT_CONNECTING = 'connecting';
const CHAT_DISCONNECTED = 'disconnected';
const CHAT_CONNECTED = 'connected';

const MEDIA_DEVICE_ERROR = {
  PERMISSION_DENIED: 'permissionsDenied',
  HARDWARE_TIMEOUT: 'hardwareTimeOut',
};

const FILE_UPLOAD_ERROR = {
  SIZE_EXCEEDED: 'File size is too large.',
  UPLOAD_FAILED: 'Oops! Looks like upload failed.',
  INVALID_SIZE: 'Empty file cannot be attached.',
};
// Participant types
const MAIN_PARTICIPANT = 'main-participant';
const DRAGGABLE_PARTICIPANT = 'draggable-participant';

const BG_KEY_LOCAL_STORAGE = 'lastBg';

const CUSTOM_ACTIVE_EVENT = 'activeEvent';

// CLAIMED ROOM CALLING STATES
export const CALLING_STATE = 'calling';
export const RINGING_STATE = 'ringing';
export const ANSWERED_STATE = 'answered';
export const UNANSWERED_STATE = 'unanswered';
export const REJECTED_STATE = 'rejected';
export const CANCELLED_STATE = 'cancelled';
export const ENDED_STATE = 'ended';

// CLAIMED ROOM DATAREF PATHS
export const ROOM_INFO_REF_PATH = 'roomInfo';
export const USER_INFO_REF_PATH = 'userInfo';
export const CALL_STATUS_CLOUD_FUNCTION_PATH =
  'https://us-central1-staging-connectaw.cloudfunctions.net/setRoomStatus';

// Claim room Constants
const REQUEST_TO_JOIN_CALL = 'REQUEST_TO_JOIN_CALL';
const JOIN_REQUEST_SENT = 'JOIN_REQUEST_SENT';
const JOIN_REQUEST_ACCEPTED = 'JOIN_REQUEST_ACCEPTED';
const JOIN_REQUEST_DENIED = 'JOIN_REQUEST_DENIED';
const JOIN_REQUEST_PENDING = 'JOIN_REQUEST_PENDING';
const REQUEST_JOIN_THE_FULL_ROOM_CALL = 'REQUEST_JOIN_THE_FULL_ROOM_CALL';
const REMOTE_SCREEN_TRACK_STARTED = 'REMOTE_SCREEN_TRACK_STARTED';
export const ROOM_LOCK_STATUS_RESPONSE = 'ROOM_LOCK_STATUS_RESPONSE';
const FOCUS_PARTICIPANT = 'FOCUS_PARTICIPANT';

// Plan types
const freemium = ['free'];
const premium = ['pro', 'business'];

const RECORDING_QUALITY_LOW = 'low';
const RECORDING_QUALITY_HIGH = 'high';
const RECORDING_QUALITY_MEDIUM = 'medium';

// white label upload types
const IMAGES = 'bgImages';
const GRADIENTS = 'gradients';
const IMAGE_UPLOAD = 'imageUpload';
const PRE_CALL_VIEW = 'preCallView';
const CALL_VIEW = 'inCallView';
const PRE_CALL_VIEW_RESOURCE_ID = 'teleport_pre_call_view';
const CALL_VIEW_RESOURCE_ID = 'teleport_call_view';
// action types
const SELECTED = 'selected';
const UPLOADED = 'uploaded';

// upload types
const COVER_PICTURE = 'coverPicture';
const LOGO = 'logo';

// Upload sizes
const COVER_PIC_IMAGE_SIZE = 25 * bitRateScaleFactor; // 25 mb
const LOGO_IMAGE_SIZE = 10 * bitRateScaleFactor; // 10 mb
const PROFILE_PIC_IMAGE_SIZE = 10 * bitRateScaleFactor; // 10 mb

const COVER_PIC_RESOLUTION = 1920;
const PROFILE_PIC_RESOLUTION = 512;

const INTERVAL_ACCOUNT_ID = [
  'SEN42',
  'YH0D44',
  '91dfed2f-d29f-4302-89ee-341e9364b941',
];

const TELEPORT_USER_CONSENT_POLICY_STATUS =
  'TELEPORT_USER_CONSENT_POLICY_STATUS';

export const NOTIFICATION_BANNER_STATE = 'NOTIFICATION_BANNER_STATE';
export const NOTIFICATION_PERMISSION_STATES = {
  default: 'default',
  granted: 'granted',
  denied: 'denied',
  closed: 'closed',
};

export const AVAILABLE_MEETING_TYPE = {
  MEETING: 'MEETING',
  TELEPORT: 'TELEPORT',
  TELECAST: 'TELECAST',
};

export const BUCKET_URL = {
  STAGING: 'https://storage.googleapis.com/staging-connect/teleport',
  LIVE: 'https://storage.googleapis.com/live-connect/teleport',
};

export const validMobileBrowserAgents = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
];

export const BLUR_INTENSITY = ['no-blur', 'low-blur', 'high-blur'];
export {
  TELEPORT_USER_CONSENT_POLICY_STATUS,
  INTERVAL_ACCOUNT_ID,
  PROFILE_PIC_RESOLUTION,
  COVER_PIC_IMAGE_SIZE,
  COVER_PIC_RESOLUTION,
  LOGO_IMAGE_SIZE,
  PROFILE_PIC_IMAGE_SIZE,
  IMAGES,
  USER_OWNER_ROLES,
  SELECTED,
  GRADIENTS,
  COVER_PICTURE,
  LOGO,
  UPLOADED,
  IMAGE_UPLOAD,
  PRE_CALL_VIEW_RESOURCE_ID,
  CALL_VIEW_RESOURCE_ID,
  PRE_CALL_VIEW,
  CALL_VIEW,
  RECORDING_QUALITY_LOW,
  RECORDING_QUALITY_HIGH,
  RECORDING_QUALITY_MEDIUM,
  freemium,
  premium,
  JOIN_REQUEST_SENT,
  JOIN_REQUEST_ACCEPTED,
  JOIN_REQUEST_DENIED,
  JOIN_REQUEST_PENDING,
  REQUEST_TO_JOIN_CALL,
  REMOTE_SCREEN_TRACK_STARTED,
  REQUEST_JOIN_THE_FULL_ROOM_CALL,
  CALL_STATE,
  FOCUS_PARTICIPANT,
  E4X_AUDIO_STATE,
  maxVideoBitRate,
  maxVideoFrameRate,
  lowVideoBitRate,
  lowVideoFrameRate,
  screenShareBitRate,
  screenShareFrameRate,
  bitRateScaleFactor,
  lowVideoWidth,
  lowVideoHeight,
  maxVideoWidth,
  maxVideoHeight,
  avgVideoWidth,
  avgVideoHeight,
  maxScreenShareWidth,
  maxScreenShareHeight,
  audioTrackName,
  videoTrackName,
  videoPreviewTrackName,
  screenTrackName,
  dataTrackName,
  maxScreenShareWidthFirefox,
  maxScreenShareHeightFirefox,
  screenShareFrameRateFirefox,
  CHROME,
  opera,
  firefox,
  electron,
  safari,
  edge,
  chromeBuiltInScreenShareVersion,
  electronBuildInScreenShareVersion,
  maxNumberOfParticipants,
  peerToPeerCall,
  groupCall,
  savedMediaSettingsKey,
  endPoint,
  FREE_BILLING_RESPONSE,
  ENCODED_PLAN_TYPES,
  JBLINK_PLAN_TYPES,
  SUCCESS_RESPONSE,
  FAILURE_RESPONSE,
  ERROR_RESPONSE,
  PRO_UPGRADE_PATH,
  BUSINESS_UPGRADE_PATH,
  planType,
  PRO_TAG_LINE,
  BUSINESS_TAG_LINE,
  FREE_TAG_LINE,
  INVITE_TYPE,
  MASTER,
  DISCOVER,
  AMEX,
  MAESTRO,
  MASTERCARD,
  JCB,
  JOINROOM,
  TOGGLE_SCREEN_SHARE,
  ENDCALL,
  TOGGLE_CAMERA,
  TOGGLE_MIC,
  TOGGLE_FULLSCREEN,
  DIRECT_CALL_ANSWERED,
  INTERVAL,
  TIMEOUT,
  WINDOW_FOCUS_MODULE,
  WINDOW_BLUR_MODULE,
  SPEAKER_VIEW,
  GRID_VIEW,
  VIEW_PREFERENCES,
  CLAIMED_ROOMS,
  SUBDOMAIN,
  SUBSCRIPTION_RULES_INCLUDE,
  SUBSCRIPTION_RULES_EXCLUDE,
  NO_OF_PARTICIPANTS_TO_SUBSCRIBE,
  NO_OF_USER_SELECTED_VIDEOS,
  SIGNALLING_ERROR_CODES,
  DUPLICATE_PARTICIPANT_ERROR_CODE,
  TWILIO_TOKEN_EXPIRES_ERROR_CODE,
  STATS_FROM_DATE,
  CHAT_CONNECTING,
  CHAT_CONNECTED,
  CHAT_DISCONNECTED,
  MEDIA_DEVICE_ERROR,
  TIMEOUT_ERROR,
  FILE_UPLOAD_ERROR,
  MAIN_PARTICIPANT,
  DRAGGABLE_PARTICIPANT,
  BG_KEY_LOCAL_STORAGE,
  CUSTOM_ACTIVE_EVENT,
  ROOM_TYPE_TELECAST,
  TEAM_DEFAULT_LIMIT,
  ENV,
  FIREFOX_VIDEO_PERMISION_DESC,
  FIREFOX_VIDEO_PERMISION_TITLE,
};
